import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';

export default function Privacidad() {
	return (
		<LayoutES>
			<Seo title='Política de cookies' />
			<main>
				<div
					style={{
						margin: '0 auto',
						width: '80%',
						maxWidth: '800px',
						padding: '60px 0',
					}}
				>
					<h1 style={{ marginTop: '3rem' }}>Política de cookies</h1>
					<p>
						WEB: <a href='https://www.kuflow.com'>https://www.kuflow.com</a>
					</p>
					<p>
						Esta Política de Cookies es parte integrante del Aviso Legal y la
						Política de Privacidad de la página web www.kuflow.com (en adelante,
						el “Sitio Web” o KUFLOW). El acceso y la navegación en el Sitio Web,
						o el uso de los servicios del mismo, implican la aceptación de los
						términos y condiciones recogido en el Aviso Legal y en la Política
						de Privacidad.
					</p>
					<p>
						Con el fin de facilitar la navegación por el Sitio Web, KUFLOW, S.L.
						como titular del mismo, con CIF número B02951978 y domicilio social
						en C/ Juan Ravina Méndez, 7, Planta 2, 38009 Santa Cruz de Tenerife,
						le comunica que utiliza cookies u otros dispositivos de
						almacenamiento y recuperación de datos de funcionalidad similar (en
						adelante, las “Cookies”).
					</p>
					<p>
						En este sentido y con el objetivo de garantizar al usuario toda la
						información necesaria para su correcta navegación, ponemos a
						disposición del usuario el siguiente texto informativo sobre qué son
						las cookies, qué tipología de cookies existen en nuestra Página Web
						y cómo es posible configurarlas o deshabilitarlas.
					</p>
					<h2>1. ¿Qué es una cookie?</h2>
					<p>
						Las Cookies son archivos que contienen pequeñas cantidades de
						información que se descargan en el dispositivo del usuario cuando
						visita una página web. Su finalidad principal es reconocer al
						usuario cada vez que accede al Sitio Web permitiendo, además,
						mejorar la calidad y ofrecer un mejor uso del Sitio Web.
					</p>
					<p>
						KUFLOW utiliza cookies propias y cookies de terceros, pertenecientes
						a terceras empresas. Las Cookies son esenciales para el
						funcionamiento de Internet; no pueden dañar el equipo/dispositivo
						del usuario y, si se encuentran activadas en la configuración de su
						navegador, ayudan a identificar y resolver posibles errores de
						funcionamiento del Sitio Web.
					</p>
					<h2>2. Uso de cookies por parte del prestador</h2>
					<p>
						Mediante el acceso al Sitio Web, acepta de manera expresa la
						utilización de este tipo de Cookies en sus dispositivos. Si
						desactiva las Cookies, puede que su navegación por el Sitio Web no
						sea óptima y algunas de las utilidades de que dispone el Sitio Web
						no funcionen correctamente.
					</p>
					<p>
						Concretamente, el prestador está utilizando las Cookies para las
						finalidades que a continuación se exponen. Si en un futuro el
						prestador utilizase otras con el propósito de otorgar más y mejores
						servicios, se informará al usuario de ello.
					</p>
					<p>
						KUFLOW podrá recoger información a través de archivos como cookies o
						ficheros log sobre la navegación de los usuarios por los Sitios Web
						de su titularidad. Estos dispositivos se asocian exclusivamente a un
						único usuario y su propio dispositivo de acceso. Las cookies son
						ficheros enviados por el sitio web y almacenados en el navegador del
						usuario por medio de un servidor web para el mantenimiento de la
						sesión de navegación almacenando su dirección IP (de su ordenador) y
						otros posibles datos de navegación.{' '}
					</p>
					<p>
						KUFLOW también podrá utilizar “beacons" o imágenes electrónicas que
						permiten al sitio web contar el número de visitantes y usuarios que
						han ingresado a un sitio web en particular y acceder a ciertas
						cookies. Gracias a las cookies, resulta posible que el servidor de
						KUFLOW reconozca el navegador del ordenador utilizado por el usuario
						con la finalidad de que la navegación sea más sencilla, así como
						para medir la audiencia y parámetros del tráfico, controlar el
						progreso y número de entradas, utilizándose la información contenida
						en las cookies de forma desvinculada respecto de cualquier otro dato
						de carácter personal que pudiera constar del usuario.
					</p>
					<p>
						KUFLOW utiliza cookies para personalizar la navegación del usuario
						por los Sitios Web de su titularidad, cookies de entrada de usuario
						y cookies de sesión para equilibrar la carga, las cuales están
						excluidas del ámbito de aplicación del artículo 22.2 de la Ley de
						Servicios de la Sociedad de la Información y Comercio Electrónico
						LSSI-CE.
					</p>
					<p>
						KUFLOW utiliza cookies de analítica web para medir y analizar la
						navegación de los usuarios en los Sitios Web. Las cookies de
						analítica son aquéllas que permiten al responsable de las mismas, el
						seguimiento y análisis del comportamiento de los usuarios de los
						sitios web a los que estén vinculadas. KUFLOW utiliza los datos
						obtenidos con el fin de introducir mejoras en función del análisis
						de los datos de uso que hacen los usuarios. La analítica web no
						permite obtener información sobre el nombre, apellidos o dirección
						de correo electrónico o postal del usuario. La información obtenida
						es la relativa al número de usuarios que acceden a la web, el número
						de páginas vistas, la frecuencia y repetición de las visitas, su
						duración, el navegador utilizado, el operador que presta el
						servicio, el idioma, el terminal utilizado o la ciudad a la que está
						asignada la dirección IP.
					</p>
					<p>
						KUFLOW utiliza cookies de publicidad comportamental para la gestión
						de los espacios publicitarios en base a criterios determinados y
						cookies de complemento (plug-in) para intercambiar contenidos
						sociales. Las cookies de publicidad comportamental son aquellas que
						permiten la gestión de los espacios publicitarios que el editor del
						sitio web haya incluido en el mismo de la forma más eficaz posible.
						Estas cookies almacenan información del comportamiento de los
						usuarios obtenida a través de la observación continuada de sus
						hábitos de navegación, lo que permite mostrar publicidad en función
						del mismo.
					</p>
					<p>
						En la siguiente tabla puede consultar las cookies utilizadas en el
						sitio web de KUFLOW, la información obtenida por las mismas y su
						finalidad:
					</p>
					<h2>3. Cookies de terceros</h2>
					<table>
						<tr>
							<th>Cookie</th>
							<th>Duración</th>
							<th>Finalidad</th>
							<th>Descripción de la finalidad de la cookie</th>
						</tr>
						<tr>
							<td>gdpr_google_tagmanager</td>
							<td>2 años</td>
							<td>Permiso de cookies</td>
							<td>
								Se usa para confirmar que el usuario ha dado permiso para
								recopilar datos analíticos.
							</td>
						</tr>
						<tr>
							<td>_ga</td>
							<td>2 años</td>
							<td>Analítica</td>
							<td>Se usa para distinguir a los usuarios.</td>
						</tr>
						<tr>
							<td>_gid</td>
							<td>24 horas</td>
							<td>Analítica</td>
							<td>Se usa para distinguir a los usuarios.</td>
						</tr>
						<tr>
							<td>{`_ga_<container-id>`}</td>
							<td>2 años</td>
							<td>Analítica</td>
							<td>Se usa para mantener el estado de la sesión.</td>
						</tr>
						<tr>
							<td>{`_gac_gb_<container-id>`}</td>
							<td>90 días</td>
							<td>Analítica</td>
							<td>
								Incluye información relacionada con la campaña. Si has vinculado
								tus cuentas de Google Analytics y Google Ads, las etiquetas de
								conversión en sitio web de Google Ads leerán esta cookie, a
								menos que la inhabilites. Más información
							</td>
						</tr>
						<tr>
							<td>YSC</td>
							<td>Sesión</td>
							<td>Preferencias</td>
							<td>Registra una identificación única para mantener estadísticas de qué videos de YouTube ha visto el usuario</td>
						</tr>
						<tr>
							<td>yt-remote-device-id</td>
							<td>Persistente</td>
							<td>Preferencias</td>
							<td>Almacena las preferencias del reproductor de video del usuario usando videos de YouTube incrustados.</td>
						</tr>
						<tr>
							<td>yt-remote-connected-devices</td>
							<td>Persistente</td>
							<td>Preferencias</td>
							<td>Almacena las preferencias del reproductor de video del usuario usando videos de YouTube incrustados.</td>
						</tr>
						<tr>
							<td>yt-remote-session-app</td>
							<td>Sesión</td>
							<td>Preferencias</td>
							<td>Almacena las preferencias del reproductor de video del usuario usando videos de YouTube incrustados.</td>
						</tr>
						<tr>
							<td>yt-remote-cast-installed</td>
							<td>Sesión</td>
							<td>Preferencias</td>
							<td>Almacena las preferencias del reproductor de video del usuario usando videos de YouTube incrustados.</td>
						</tr>
						<tr>
							<td>yt-remote-session-name</td>
							<td>Sesión</td>
							<td>Preferencias</td>
							<td>Almacena las preferencias del reproductor de video del usuario usando videos de YouTube incrustados.</td>
						</tr>
					</table>
					<h2>4. Cómo rechazar o bloquear cookies</h2>
					<p>
						Puede bloquear las Cookies a través de las herramientas de
						configuración del navegador, o bien, puede configurar su navegador
						para que le avise cuando un servidor quiera guardar una Cookie:
					</p>
					<ul>
						<li>
							Si utiliza Microsoft Internet Explorer, encontrará la opción en el
							menú Herramientas > Opciones de Internet > Privacidad >
							Configuración. Para saber más{' '}
							<a href='https://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9'>
								https://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies
								http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9
							</a>
						</li>
						<li>
							Si utiliza Firefox, encontrará la opción en el menú Herramientas >
							Opciones > Privacidad > Cookies. Para saber más{' '}
							<a href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias'>
								https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
							</a>
						</li>
						<li>
							Si utiliza Chrome, en la sección de Opciones > Opciones avanzadas
							> Privacidad. Para saber más{' '}
							<a href='https://support.google.com/accounts/answer/61416?hl=es'>
								https://support.google.com/accounts/answer/61416?hl=es
							</a>
						</li>
						<li>
							Si utiliza Safari encontrará la opción en el menú
							Preferencias/Privacidad. Más información en:{' '}
							<a href='http://support.apple.com/kb/HT1677?viewlocale=es_ES'>
								http://support.apple.com/kb/HT1677?viewlocale=es_ES
							</a>
						</li>
					</ul>
				</div>
			</main>
		</LayoutES>
	);
}
